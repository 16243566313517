<template>
  <div>
    <el-tabs>
      <el-tab-pane :label="$t('event.alarmBell')">
        <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="alarmRecords/html" @clear="clearDateRange">
          <template slot="adSearch">
            <vm-search :label="$t('event.dtuNumber')">
              <el-input v-model.trim="search.dtuCode" :placeholder="$t('event.dtuNumber')" clearable></el-input>
            </vm-search>
            <vm-search :label="$t('event.alarmTime')">
              <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
                :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
                @change="handleDateChange">
              </el-date-picker>
            </vm-search>
          </template>
          <template slot="toolbar">
            <el-button :loading="exportLoading" class="greenBtn" @click="exportExcel">
              {{ $t("event.export") }}
            </el-button>
          </template>
          <table-column prop="elevatorNo" :label="$t('event.elevatorNo')" :width="125" align="center">
            <template #default="scope">
              <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{ scope.row.elevatorNo
                }}</router-link>
            </template>
          </table-column>
          <table-column prop="elevatorName" :label="$t('event.elevatorName')"></table-column>
          <table-column prop="dtuCode" :label="$t('event.dtuNumber')" :width="130" align="center"></table-column>
          <table-column prop="nodeCode" :label="$t('event.node')" width="90px" align="center"></table-column>
          <table-column width="100px" align="center" :label="$t('common.status')">
            <template #default="scope">
              <el-image alt="困人" v-if="scope.row.status === 1" src="/static/images/elevator/guanren.png"></el-image>
            </template>
          </table-column>
          <table-column prop="alarmTime" :label="$t('event.alarmTime')" :width="160" align="center"></table-column>
          <table-column v-if="editAuth" :label="$t('common.operation')" :width="100" align="center" fixed="right" :tooltip="false">
            <template #default="scope">
              <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
                }}</el-button>
            </template>
          </table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane :label="$t('event.callRecord')">
        <call-record-list></call-record-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
const moduleName = "alarmRecords";

import CallRecordList from "@/views/event/CallRecordList";

export default {
  components: { CallRecordList },
  data() {
    return {
      editAuth: this.$auth(342),
      exportLoading: false,
      loading: false,
      dateRange: [],
      search: {
        filter: "",
        startTime: "",
        endTime: "",
        dtuCode: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageIndex) {
      this.loading = true;
      this.$refs.vmTable.getList(pageIndex);
      this.loading = false;
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("event.elevatorNo") + " " + row.elevatorNo + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.alarmRecordId).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
          }
        });
      });
    },
    exportExcel() {
      if (this.dateRange.length === 0) {
        this.$message.error(this.$t("event.tip.alarmTime"));
        return;
      }
      let fullTime = [];
      for (let i = 0; i < this.dateRange.length; i++) {
        let time = this.dateRange[i].split(" ");

        let fullYear = time[0].split("-");
        let year = fullYear[0];
        let month = fullYear[1];
        let day = fullYear[2];

        let fullDay = time[1].split(":");
        let hour = fullDay[0];
        let minute = fullDay[1];
        let second = fullDay[2];

        let date = new Date(year, month, day, hour, minute, second);
        fullTime.push(date.getTime());
      }
      if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) > 7) {
        this.$message.error(this.$t("event.tip.alarmTime"));
        return;
      }
      this.exportLoading = true;
      let params = {
        startTime: this.dateRange != null ? this.dateRange[0] : "",
        endTime: this.dateRange != null ? this.dateRange[1] : "",
        isCn: this.$i18n.isCn ? 1 : 0,
        filter: this.search.filter,
      };
      this.$api
        .exportFile("alarmRecords/export", params, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = this.$t("event.alarmFileName");
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>
<style lang="scss" scoped></style>
